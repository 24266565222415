import { gql, useQuery } from '@apollo/client';

export const GET_FAQS_QUERY = gql`
  query GetFAQsQuery($productId: ID!) {
    allFaq(where: { product: { _id: { eq: $productId } } }) {
      _id
      qnas {
        question {
          en
          de
        }
        answer {
          enRaw
          deRaw
        }
      }
    }
  }
`;

/**
 * Hook to get FAQs about a product.
 */
export function useFAQs({ productId }) {
  const { data, ...rest } = useQuery(GET_FAQS_QUERY, {
    variables: {
      productId,
    },
  });

  return {
    faqs: data?.allFaq?.[0]?.qnas ?? [],
    ...rest,
  };
}

export const GET_GLOBAL_FAQS_QUERY = gql`
  query GetGlobalFaqsQuery {
    SiteConfig(id: "global-config") {
      _id
      faqs {
        question {
          en
          de
        }
        answer {
          enRaw
          deRaw
        }
      }
    }
  }
`;

/**
 * Hook to get FAQs for the whole app.
 */
export function useGlobalFAQs() {
  const { data, ...rest } = useQuery(GET_GLOBAL_FAQS_QUERY);

  return {
    faqs: data?.SiteConfig?.faqs ?? [],
    ...rest,
  };
}
